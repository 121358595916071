import {Aurelia} from 'aurelia-framework'
import {PLATFORM} from 'aurelia-pal';
import {TCustomAttribute} from "aurelia-i18n";
import Backend from 'i18next-xhr-backend';
import 'jquery';
import 'spectrum-colorpicker';
import 'spectrum-colorpicker/spectrum';

// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
const Bluebird = require("bluebird");
Bluebird.config(
    {
        monitoring: false,
        warnings: false, // { wForgottenReturn: false },
        longStackTraces: false
    }
);

export function configure(aurelia: Aurelia) {
    // ie has some awful issues with non existing logging. Add the missing log classes to prevent fatals:
    if (!console.info) console.info = console.log;
    if (!console.debug) console.debug = console.info;

    aurelia.use
        .standardConfiguration()
        // .developmentLogging(ConfigService.Debug ? 'debug' : 'warn')
        .plugin(PLATFORM.moduleName('aurelia-i18n'), (instance) => {
            let aliases = ['t', 'i18n'];
            // add aliases for 't' attribute
            TCustomAttribute.configureAliases(aliases);

            // register backend plugin
            instance.i18next.use(Backend);

            // adapt options to your needs (see http://i18next.com/docs/options/)
            // make sure to return the promise of the setup method, in order to guarantee proper loading
            let result = instance.setup({
                backend: {                                  // <-- configure backend settings
                    loadPath: 'locales/{{lng}}/{{ns}}.json', // <-- XHR settings for where to get the files from
                },
                ns: ['translation','overrides'],
                defaultNS: ['overrides', 'translation'],
                attributes: aliases,
                lng: 'de',
                fallbackLng: 'de',
                debug: false
            });

            this.i18 = instance;

            return result;
        })
        .plugin(PLATFORM.moduleName('aurelia-dialog')) // i18n refers to https://github.com/aurelia/i18n
        .plugin(PLATFORM.moduleName('aurelia-bootstrap-datetimepicker')) //, config => { }) /* seee: https://www.npmjs.com/package/aurelia-bootstrap-datetimepicker */
        .plugin(PLATFORM.moduleName('aurelia-bootstrap'))
        //        .plugin(PLATFORM.moduleName('aurelia-slickgrid'))
        .plugin(PLATFORM.moduleName('aurelia-validation'))
        .plugin(PLATFORM.moduleName('aurelia-autocomplete'))
        .globalResources([])
        .feature(PLATFORM.moduleName('resources/index'));

    /* This tells Aurelia that by default we want our logs to go to the console,
    although we could also direct them to a remote logging service,
    and also sets the level at which we’d like to display them. */
    /* LogManager.addAppender(new ConsoleAppender());
    LogManager.setLevel(
        window.location.href.indexOf('/localhost') > -1
        ? LogManager.logLevel.debug
        : LogManager.logLevel.error
    ); */

    aurelia.use.developmentLogging('warn');
    return aurelia.start().then(() => {
        // if this runs in ios Simulator we need to refresh the screen constantly because of a an openGL Bug in the simulator
        //
        aurelia.setRoot(PLATFORM.moduleName('app')).then(() => {
            // NitTools.invalidateScreen();
        })
    });
}
